<template>
  <div class="container">
    <Actions :items="detail.actions" :right="true" />
    <MobileTable
      v-model:selection="store.state.commonExpense.selectedCommonExpenseDetail"
      :label="$route.name"
      :columns="detail.columns"
      :data="store.state.commonExpense.commonExpenseDetail"
      :rows="50"
    />
    <DesktopTable
      v-model:selection="store.state.commonExpense.selectedCommonExpenseDetail"
      :label="$route.name"
      :columns="detail.columns"
      :data="store.state.commonExpense.commonExpenseDetail"
      :rows="50"
    />
    <BasicModal v-model:visible="detail.showModal">
      <template v-slot:dialog>
        <BasicTitle title="Detalle del Pago" />
        <div class="container-form">
          <BasicLabel label="No. Recibo" />
          <BorderInput :label="detail.data.document" />
          <FormError />
          <BasicLabel label="Monto" />
          <BorderInput :label="detail.data.amount" />
          <FormError />
          <BasicLabel label="Fecha" />
          <BorderInput :label="detail.data.date" />
          <FormError />
          <BasicLabel label="Forma de Pago" />
          <BorderInput :label="detail.data.type" />
          <FormError />
          <BasicLabel v-if="detail.data.voucher" label="No. Comprobante" />
          <BorderInput
            v-if="detail.data.voucher"
            :label="detail.data.voucher"
          />
          <FormError v-if="detail.data.voucher" />
          <BasicLogo
            v-if="detail.data.voucher"
            width="350"
            :src="detail.data.image"
            alt="Comprobante de Pago"
          />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal";
import { onBeforeMount, onBeforeUnmount, reactive } from "vue";
import store from "../../store";
import { actions, currency, dates, aws } from "../../constants";

import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import FormError from "../../widgets/error/FormError";
import BasicLogo from "../../widgets/logo/BasicLogo.vue";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicModal,
    BasicTitle,
    BasicLabel,
    BorderInput,
    FormError,
    BasicLogo,
  },
  setup() {
    const route = useRoute();
    const onConfirm = async () => {
      if (!store.state.commonExpense.selectedCommonExpenseDetail.length) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        const payments = store.state.commonExpense.selectedCommonExpenseDetail.map(
          (item) => ({
            id: item.id,
            apartment: item.apartment,
            period: item.period,
            totalDebt: item.totalDebt,
            document: item.document,
            payment: item.payment,
          })
        );
        if (!payments.length) {
          store.state.toast.add({
            severity: "info",
            summary: "",
            detail: "No se ha recibido ningún pago!",
            life: 5000,
          });
          store.commit("setSelectedCommonExpenseDetail", []);
          return;
        }

        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.commonExpenseActions.confirm,
          { payments }
        );
        if (response.ok) {
          store.state.toast.add({
            severity: "success",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setSelectedCommonExpenseDetail", []);
          await store.dispatch(
            actions.commonExpenseActions.findAllDetail,
            store.state.commonExpense.selectedCommonExpenses[0].id
          );
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    };

    const onDetail = async () => {
      if (store.state.commonExpense.selectedCommonExpenseDetail.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (
        !store.state.commonExpense.selectedCommonExpenseDetail[0].payment
      ) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "No hay ningún pago para este registro!",
          life: 5000,
        });
      } else {
        const selected =
          store.state.commonExpense.selectedCommonExpenseDetail[0];
        detail.data.document = selected.payment.document.name;
        detail.data.amount = currency.convertNumberToCurrency(
          selected.payment.amount
        );
        detail.data.date = dates.dateToString(selected.payment.date);
        detail.data.type = selected.payment.paymentType;
        detail.data.voucher =
          selected.payment.voucher && selected.payment.voucher.name;

        const callback = (str) => {
          detail.data.image = `data:image/png;base64,${str}`;
          detail.showModal = true;
        };

        if (detail.data.voucher) {
          const url = `${selected.payment.voucher.path}${selected.payment.voucher.name}${selected.payment.voucher.ext}`;
          aws.getFile(url, callback);
        } else {
          detail.showModal = true;
        }
      }
    };

    const onDownloadExcel = async () => {
      const request = {
        fileName: `Detalle de Gastos Comunes (${store.state.commonExpense.selectedCommonExpenses[0].period})`,
        headers: detail.columns.map((item) => item.header),
        data: store.state.commonExpense.commonExpenseDetail.map((item) => [
          item.tower,
          item.apartment,
          item.owner,
          `${item.prevBalance}`,
          `${item.payments}`,
          `${item.balance}`,
          `${item.surcharge}`,
          `${item.commonExpenses}`,
          `${item.extraFee}`,
          `${item.reserveFound}`,
          `${item.commonAreas}`,
          `${item.totalDebt}`,
          item.indPayment,
        ]),
      };
      store.commit("setLoading", true);
      await store.dispatch(actions.commonExpenseActions.downloadExcel, request);
      store.commit("setLoading", false);
    };

    const detail = reactive({
      showModal: false,
      data: {
        document: "",
        amount: "",
        date: "",
        type: "",
        voucher: "",
        image: "",
      },
      columns: [
        {
          field: "tower",
          header: "Torre",
          sort: true,
        },
        {
          field: "apartment",
          header: "Apartamento",
          sort: true,
        },
        {
          field: "owner",
          header: "Propietario o Inquilino (P o I)",
          sort: true,
        },
        {
          field: "prevBalance",
          header: "Saldo mes anterior",
          sort: true,
          currency: true,
        },
        {
          field: "payments",
          header: "Pagos",
          sort: true,
          currency: true,
        },
        {
          field: "balance",
          header: "Saldo mes actual",
          sort: true,
          currency: true,
        },
        {
          field: "surcharge",
          header: "Recargo sobre deuda",
          sort: true,
          currency: true,
        },
        {
          field: "commonExpenses",
          header: "Gastos comunes",
          sort: true,
          currency: true,
        },
        {
          field: "extraFee",
          header: "Cuota extra",
          sort: true,
          currency: true,
        },
        {
          field: "reserveFound",
          header: "Fondo reserva",
          sort: true,
          currency: true,
        },
        {
          field: "commonAreas",
          header: "Áreas comunes",
          sort: true,
          currency: true,
        },
        // {
        //   field: "totalMonth",
        //   header: "Total del mes",
        // },
        {
          field: "totalDebt",
          header: "Total a pagar",
          sort: true,
          currency: true,
        },
        {
          field: "indPayment",
          header: "Pago",
          sort: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Confirmar Pago",
          action: onConfirm,
          hidden: true,
        },
        {
          name: "Ver detalle de Pago",
          action: onDetail,
          hidden: true,
        },
        {
          name: "Descargar Excel",
          action: onDownloadExcel,
          hidden: true,
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedCommonExpenseDetail", []);
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.commonExpenseActions.findAllDetail,
        store.state.commonExpense.selectedCommonExpenses[0].id
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    return { store, detail };
  },
};
</script>

<style scoped></style>
